import { type CSSProperties, memo, useCallback, useEffect, useState } from 'react';
import { NavigationMenuButton } from '@components/common/NavigationMenuButton';
import { links } from '@data/navigationBar';
import useLatest from '@hooks/useLatest.tsx';

const navigationContainerStyle = {
    '--top-padding': 'calc(var(--topbar-height) * 2)',
} as CSSProperties;

const NavigationBarMenuButton = () => {
    const [buttonType, setButtonType] = useState<'hamburger' | 'arrow'>('arrow');
    const buttonTypeRef = useLatest(buttonType);

    const scrollListener = useCallback(() => {
        const header = document.querySelector('.product-page-navigation-bar');

        if (!header) return;
        if (header.classList.contains('product-page-navigation-bar--sticky')) {
            if (buttonTypeRef.current === 'hamburger') return;

            setButtonType('hamburger');
        } else {
            if (buttonTypeRef.current === 'arrow') return;

            setButtonType('arrow');
        }
    }, []);

    useEffect(() => {
        window.addEventListener('scroll', scrollListener);

        return () => window.removeEventListener('scroll', scrollListener);
    }, [scrollListener]);

    return (
        <NavigationMenuButton
            menu={links}
            buttonType={buttonType}
            navigationContainerStyle={navigationContainerStyle}
        />
    );
};

export default memo(NavigationBarMenuButton);
