export const links = [
    {
        id: 1,
        title: 'Funktionen',
        link: '#features',
    },
    {
        id: 2,
        title: 'Preise',
        link: '#price',
    },
    {
        id: 2,
        title: 'Kundenmeinungen',
        link: '#kundenmeinungen',
    },
    /*{
        id: 2,
        title: 'Demo-Konfigurator',
        link: '#Kundenmeinungen',
    },*/
    {
        id: 3,
        title: 'Kontakt',
        link: '#contact',
    },
];
